import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import {getStorage} from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAS5sk4wIqlSG8qfTFEu05eSp_BjhJNtek",
  authDomain: "logistics-9a640.firebaseapp.com",
  projectId: "logistics-9a640",
  storageBucket: "logistics-9a640.appspot.com",
  messagingSenderId: "798440950634",
  appId: "1:798440950634:web:13206ec00c05bc1cfcb313"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);  
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export default app