import React, { useState, useEffect } from "react";

export default function Expired() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    // Check system preference for dark mode
    const prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
    setIsDarkMode(prefersDark);
  }, []);

  return (
    <div
      className={`min-h-screen flex flex-col items-center justify-center px-6 text-center transition-all ${
        isDarkMode ? "bg-gray-900 text-white" : "bg-gray-50 text-gray-900"
      }`}
    >
      <h1 className="text-3xl md:text-6xl font-semibold">Hmm. We&apos;re having trouble finding that site.</h1>
      <p className="mt-4 text-left space-y-2 text-lg md:text-2xl">
        We can’t connect to the server at <span className="font-bold">www.eugogatelogistics.com</span>.
      </p>

      <div className="mt-6 text-left space-y-2 text-lg md:text-2xl">
        <p className="font-semibold">If you entered the right address, you can:</p>
        <ul className="list-disc list-inside space-y-1">
          <li>Try again later</li>
          <li>Check your network connection</li>
          <li>
            Check that your browser has permission to access the web (you might be connected but behind a firewall)
          </li>
        </ul>
      </div>

    <div className="flex justify-center items-center md:justify-end">
        <button
            className="mt-6 px-6 py-2 rounded-lg bg-blue-500 text-white text-lg font-medium hover:bg-blue-600 transition"
            onClick={() => window.location.reload()}
        >
            Try Again
        </button>
    </div>
      
    </div>
  );
}
