import React, { useState } from "react";
import "./App.css";
import Layout from "./components/Layout/Layout";
import Expired from "./components/Expired/Expired";


function App() {
  
  return (
  <>
   <Expired/>
  {/*
    <Layout />
  */}
  </>
  );
}

export default App;
